<template id="">
  <!-- <body>
    <h1>本登録(2/2)</h1>
    <p>部署名</p>
    <template id=""  v-for="i in data_num" :key=i>
      <input type="text" name="" v-model="busyo[i-1]"><br>
    </template>
    <button type="button" name="button" @click="add_data">項目追加</button>
    <button type="button" name="button" @click="send_data">登録</button>
  </body> -->
  <div>
    <div class="regist">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h3 class="uk-text-background">本登録&nbsp;（2/2）</h3>
      <div v-show="$store.state.common.sIsLoading">Loading...</div>
      <div
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <div class="uk-flex uk-flex-center">
          <div class="uk-container">
            <div class="uk-text-center" uk-grid>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted">部署名</label>
                  </div>
                  <input class="uk-input" type="text" v-model="busyo[0]" />
                </div>
              </div>
              <div class="uk-width-1-1" v-for="i in data_num" :key="busyo[i]">
                <div class="uk-margin">
                  <input class="uk-input" type="text" v-model="busyo[i]" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <span uk-icon="icon: plus-circle" @click="add_data"
                    >項目追加&nbsp;</span
                  >
                </div>
              </div>
              <div class="uk-width-1-1 uk-flex uk-flex-center">
                <div class="uk-width-1-2">
                  <div class="uk-margin">
                    <button
                      class="uk-button uk-button-primary uk-width-1-1"
                      type="button"
                      @click="send_data"
                    >
                      登録
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <p style="color:red;"><b>{{ vMessage }}</b></p> -->
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      data_num: 3,
      busyo: [],
      do_something: true,
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.titile = "本登録";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    var str;
    str = this.$route.path;
    this.uuid = str.replace(/\*/, "");
    this.uuid = this.uuid.replace("/client-registration1/", "");
    console.log(this.uuid);
    this.$store.state.common.sDataLoading = false;
  },
  methods: {
    add_data() {
      this.data_num += 1;
    },
    send_data() {
      if (!this.do_something) return;
      this.do_something = false;
      if (this.$store.state.first_registration == false) {
        alert("不正な操作です。お手数ですが再度やり直してください。");
        this.do_something = true;
        this.$router.replace("/client-login");
        return;
      }
      this.$axios
        .post("/client/registration1", {
          uuid: this.uuid,
          busyo_data: this.busyo,
          alias_id: this.$store.state.alias_id,
        })
        .then(
          function (response) {
            //eslint-disable-line
            if (response.data.flg) {
              alert("登録が完了しました");
              this.do_something = true;
              this.$router.replace("/client-login");
            } else {
              if (response.data.status == 3) {
                alert("不正な操作です。お手数ですが再度やり直してください。");
              }
            }
            this.do_something = true;
            this.$router.replace("/client-login");
          }.bind(this)
        )
        .catch(
          function (error) {
            this.state = false;
            this.do_something = true;
            console.log(error);
          }.bind(this)
        );
    },
  },
};
</script>
